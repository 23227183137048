import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, LinearProgress, Stack } from '@mui/material';
import { format } from 'date-fns';
import CountUp from 'react-countup';

const Ticket = () => {
    const [key] = useState('tickets')
    const [oldData, setOldData] = useState(0);
    const [start, setStart] = useState();
    const [currentData, setCurrentData] = useState(() => {
        const savedData = localStorage.getItem(key);
        if (savedData) setStart(savedData);
        return savedData ? JSON.parse(savedData) : null;
    });

    // Set Date 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const currentDate = new Date();
    const startDate = getStartMonthOfYear();
    const startDateOfYear = format(startDate, 'dd-MM-yyyy');
    const currentDateOfYear = format(currentDate, 'dd-MM-yyyy');

    useEffect(() => {
        const dataStorage = window.localStorage.getItem(key);
        const getTickets = async () => {
            try {
                const response = await axios.get('https://api.angkorenterprise.gov.kh/statistic/sale_ticket2');
                if (response.status === 200) {
                    const tickets = response.data?.number_tickets;
                    setCurrentData(tickets);
                    if (currentData >= dataStorage) setStart(dataStorage)
                    if (currentData >= oldData && !dataStorage) setStart(oldData);
                    setOldData(tickets);
                } else { return; }

            } catch (error) {
                console.error('Error fetching tickets:', error);
            };
        };
       
        if (currentData === null || dataStorage !== null) getTickets();

        const savedTimestamp = localStorage.getItem(`${key}_timestamp`);
        const currentTime = Date.now();

        if (savedTimestamp && currentTime - savedTimestamp > 86400000) {
            localStorage.removeItem(key);
            localStorage.removeItem(`${key}_timestamp`);
        } else {
            if (currentData !== null) {
                localStorage.setItem(key, JSON.stringify(currentData));
                localStorage.setItem(`${key}_timestamp`, currentTime.toString());
            }
        }

        const interval = setInterval(() => {
            getTickets();
        }, 60000);

        const timer = setTimeout(() => {
            localStorage.removeItem(key);
        }, 86400000);

        return () => {
            clearInterval(interval);
            clearTimeout(timer)
        };

    }, [currentData, currentDate, key, oldData]);

    return (
        <main className="grid">
            <div style={{ textAlign: 'center', marginTop: '200px' }}>
                <img
                    src={'/images/logo.svg'}
                    alt="profile"
                    width={250}
                    height={250}
                    style={{ margin: 'auto' }}
                />
            </div>
            <div className="text-center" style={{ marginTop: '110px' }}>
                <div className='khmer-os-moul text-[52px]'>
                    <Box sx={{ fontWeight: '800', filter: 'drop-shadow(0px 10px 0px #00000059)' }}>
                        <h3 className="text-[#FFFFFF]">ចំនួនភ្ញៀវទេសចរបរទេសទិញ</h3>
                        <h3 className="text-[#FFFFFF]" style={{ marginTop: '40px' }}>បណ្ណចូលទស្សនា រមណីយដ្ឋានអង្គរ</h3>
                    </Box>
                </div>
                <div className='font-sans text-[50px]' style={{ marginTop: "35px" }}>
                    <Box sx={{ fontWeight: '800', filter: 'drop-shadow(0px 12px 0px #00000059)' }}>
                        <h3 className="text-[#FFFFFF]">The number of foreign tourists</h3>
                        <h3 className="text-[#FFFFFF]">purchasing Angkor Pass</h3>
                    </Box>
                </div>

                <div className="text-[#FFFFFF] font-sans" style={{ marginTop: "10px" }}>
                    <Box sx={{ fontWeight: '800', filter: 'drop-shadow(0px 10px 0px #00000059)' }}>
                        <div className='flex justify-center gap-50' style={{ padding: '100px' }}>
                            <label className='text-[35px] mt-2'>From</label>
                            <span className='text-[45px] text-[#F5E866] ml-10 mr-10'>{startDateOfYear}</span>
                            <label className='text-[35px] mt-2'>To</label>
                            <span className='text-[45px] text-[#F5E866] ml-10'>{currentDateOfYear}</span>
                        </div>
                    </Box>
                </div>

                <div className='font-sans' style={{ marginTop: "-30px" }}>
                    {currentData != null ?
                        <Box sx={{ fontWeight: '700', filter: 'drop-shadow(0px 10px 0px #00000059)' }}>
                            <CountUp className="text-[100px] text-[#F5E866]" start={start} duration={1} end={currentData} decimal="," />
                        </Box> :

                        <>
                            <Stack sx={{ width: '10%', color: '#EEEEEE', margin: 'auto' }} spacing={2}>
                                <span className='text-[20px]'>{'Loading...'}</span>
                                <LinearProgress color="inherit" className='bg-[#7F8487]' />
                            </Stack>
                        </>
                    }
                </div>
            </div>
        </main>
    )
}

export default Ticket

const getStartMonthOfYear = () => {
    const now = new Date(); // current date
    const year = now.getFullYear(); // Get the current year
    const startOfMonth = new Date(year, 0, 1); // January 1st of the current year
    return startOfMonth; // Get the month of the start of the year
};

